// Generated by Framer (d89babb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["F9kX0Bmou", "JlpPZCVnY"];

const serializationHash = "framer-IMCm2"

const variantClassNames = {F9kX0Bmou: "framer-v-268jlv", JlpPZCVnY: "framer-v-1e9bfab"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "F9kX0Bmou", Open: "JlpPZCVnY"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "F9kX0Bmou"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "F9kX0Bmou", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-268jlv", className, classNames)} data-framer-name={"Closed"} layoutDependency={layoutDependency} layoutId={"F9kX0Bmou"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({JlpPZCVnY: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-18vjspj"} data-framer-name={"Frame"} layoutDependency={layoutDependency} layoutId={"zOKpMzY7T"} style={{rotate: 0}} variants={{JlpPZCVnY: {rotate: -180}}}><SVG className={"framer-sr232d"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"Jce1SfhL8"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 30 30\"><path d=\"M 7.5 13.75 L 17.5 23.75 L 27.5 13.75\" fill=\"transparent\" stroke-width=\"5\" stroke=\"rgb(0, 0, 0)\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={2275875352} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IMCm2.framer-1v78ss2, .framer-IMCm2 .framer-1v78ss2 { display: block; }", ".framer-IMCm2.framer-268jlv { height: 30px; overflow: visible; position: relative; width: 30px; }", ".framer-IMCm2 .framer-18vjspj { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-IMCm2 .framer-sr232d { flex: none; height: 30px; left: calc(50.00000000000002% - 30px / 2); position: absolute; top: calc(50.00000000000002% - 30px / 2); width: 30px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"JlpPZCVnY":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerACwEzeaex: React.ComponentType<Props> = withCSS(Component, css, "framer-IMCm2") as typeof Component;
export default FramerACwEzeaex;

FramerACwEzeaex.displayName = "Caret Copy 2";

FramerACwEzeaex.defaultProps = {height: 30, width: 30};

addPropertyControls(FramerACwEzeaex, {variant: {options: ["F9kX0Bmou", "JlpPZCVnY"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerACwEzeaex, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})